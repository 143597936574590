import randomstring from 'randomstring';
import { UseFormMethods } from 'react-hook-form';
import { SelectOptions } from '@cyber-cats/uds/elements';

import {
  BaseFieldProps,
  CustomCheckbox,
  CustomDropdown,
  CustomDropdownProps,
  CustomButton,
  CustomLink,
  CustomInput,
  FieldPropsConfig,
  FormField,
  FormFieldConfig,
  CustomInputRadioGroup,
  CustomInputRadioProps,
  CustomTermsAndConditions,
  CustomBirthday,
  RadioOption,
} from 'ui/forms/custom/types';
import { Resources } from 'locales/localeType';
import { toKebabCase } from 'utils/toKebabCase';
import { TransType } from 'hooks/useTranslations';
import { FormConfig } from 'sites/types';

type Form = UseFormMethods<any>;

export const translateSelectOptions = (
  options: SelectOptions,
  t: TransType
) => {
  return options.map(option => ({
    label: t(option.label as keyof Resources) || option.label,
    value: option.value,
  }));
};

export const translateInputRadioValues = (
  items: string[],
  t: TransType
): RadioOption[] => {
  return items.map(item => ({
    value: item,
    label: t(item as keyof Resources) || item,
  }));
};

export const getSelectLabel = (
  id: string | null | undefined,
  options?: SelectOptions
): string => {
  if (!options || !id) return '';
  const option = options.find(option => option.value === id);
  return option?.label || '';
};

export const buildName = (name: string, prefix?: string) =>
  prefix ? `${prefix}.${name}` : name;

export const buildPlaceholder = (name: string) =>
  `${name}Placeholder` as keyof Resources;

export const buildPatternError = (name: string) =>
  `${name}PatternError` as keyof Resources;
export const buildLabel = (name: string) => `${name}Label` as keyof Resources;

export const buildId = (name: string, prefix?: string) => {
  const formattedName = toKebabCase(name);
  if (!prefix) {
    // Add an id prefix in order to avoid duplicate field ids
    return `${randomstring.generate(8)}-${name}`;
  } else {
    const formattedPrefix = toKebabCase(prefix);
    return `${formattedPrefix}-${formattedName}`;
  }
};

export const buildDataTestId = (name: string) => toKebabCase(name);

export const setValuesOnStateCodeChange = (
  addressForm: Form,
  prefix?: string
) => {
  if (!prefix) return;
  addressForm.setValue(buildName('stateCode', prefix), undefined);
  addressForm.clearErrors([buildName('stateCode', prefix)]);
  addressForm.trigger(buildName('postalCode', prefix));
};

export const validateStateCode = (addressForm: Form, prefix?: string) =>
  addressForm.trigger(buildName('stateCode', prefix));

export interface CheckoutAddressHelpers {
  setValuesOnStateCodeChange: (addressForm: Form, prefix?: string) => any;
  validateStateCode: (addressForm: Form, prefix?: string) => any;
}

export const checkoutAddressHelpers = {
  setValuesOnStateCodeChange,
  validateStateCode,
};

export const buildBaseInputProps = (
  name: string,
  prefix,
  t: TransType
): BaseFieldProps => {
  return {
    key: buildName(name, prefix),
    name: buildName(name, prefix),
    id: buildId(name, prefix),
    dataTestId: buildDataTestId(name),
    placeholder: t(buildPlaceholder(name)),
    label: t(buildLabel(name)),
    patternError: t(buildPatternError(name)),
  };
};

export const getFieldByType = (
  config: FormFieldConfig,
  t: TransType,
  prefix?: string
): FormField => {
  const sharedProps = {
    fieldType: config.type,
    ...buildBaseInputProps(config.name, prefix, t),
  };
  switch (config.type) {
    case 'terms-and-conditions':
      return {
        ...sharedProps,
        page: config.name,
      } as CustomTermsAndConditions;
    case 'button':
      return {
        ...sharedProps,
        type: config.buttonType,
      } as CustomButton;
    case 'link':
      return {
        ...sharedProps,
      } as CustomLink;
    case 'input-radio':
      return {
        ...sharedProps,
      } as CustomInputRadioGroup;
    case 'checkbox':
      return sharedProps as CustomCheckbox;
    case 'dropdown': {
      return {
        ...sharedProps,
        options: [],
      } as CustomDropdown;
    }
    case 'birthday': {
      return {
        ...sharedProps,
        hideSupportText: config.hideSupportText,
      } as CustomBirthday;
    }
    case 'password':
    case 'google-address-look-up':
    case 'input':
    default:
      return {
        ...sharedProps,
        inputType: config.inputType,
        maxLength: 255,
      } as CustomInput;
  }
};

export const resolveField = (
  fieldConfig: FormFieldConfig,
  fieldProps: Record<string, FieldPropsConfig>,
  t: TransType,
  prefix?: string
): FormField => {
  const field: FormField = getFieldByType(fieldConfig, t, prefix);
  const fieldProp = fieldProps[fieldConfig.name];

  if (fieldProp) {
    if (fieldConfig.type === 'dropdown') {
      const options: SelectOptions = [
        ...translateSelectOptions(
          (fieldProps[fieldConfig.name] as CustomDropdownProps).options || [],
          t
        ),
      ];
      return { ...field, ...fieldProp, options } as FormField;
    }
    if (fieldConfig.type === 'input-radio') {
      const values = [
        ...translateInputRadioValues(
          (fieldProps[fieldConfig.name] as CustomInputRadioProps).values || [],
          t
        ),
      ];
      return { ...field, ...fieldProp, values } as FormField;
    }
    return { ...field, ...fieldProp } as FormField;
  }

  return { ...field };
};

export const hasAllShippingFields = (
  shippingAddress,
  mandatoryFields,
  fields?: Record<string, FieldPropsConfig>
) => {
  const requiredFields =
    mandatoryFields && mandatoryFields.length
      ? mandatoryFields
      : [
          'firstName',
          'lastName',
          'address1',
          'postalCode',
          'stateCode',
          'countryCode',
          'phone',
        ];

  return (
    shippingAddress &&
    requiredFields.every(field => {
      const fieldProp = fields?.[field];
      const regExpValidation =
        fieldProp && 'pattern' in fieldProp ? fieldProp.pattern : undefined;
      return (
        shippingAddress[field] &&
        (!regExpValidation ||
          new RegExp(regExpValidation).test(shippingAddress[field]))
      );
    })
  );
};

export const mandatoryFieldsNames = (form: FormConfig): string[] => {
  const names: string[] = [];
  for (const fields of form) {
    for (const field of fields) {
      if (field.name && field.required) {
        names.push(field.name);
      }
    }
  }
  return names;
};
